.App-header {
  background-color: #F2F2F2;
  font-size: calc(10px + 2vmin);
  width: max-content;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
}

.App-header/Home-button {
  float: left;
}

.App-header/About-button {
  float: right;
}